import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">



<path d="M6250 11949 c-1629 -81 -3129 -857 -4142 -2143 -622 -788 -1022
-1746 -1158 -2771 -64 -482 -49 -1179 36 -1635 97 -525 214 -913 407 -1350 87
-196 242 -494 332 -635 18 -27 52 -81 76 -120 111 -178 301 -433 474 -635 101
-118 424 -446 530 -537 39 -33 93 -80 121 -104 82 -72 248 -199 362 -278 105
-73 398 -261 407 -261 2 0 39 -21 82 -46 102 -60 335 -177 497 -249 775 -342
1646 -504 2500 -466 1008 46 1920 334 2783 879 189 119 487 337 588 429 11 10
52 45 90 78 198 168 530 515 675 705 19 25 37 47 40 50 12 10 128 167 196 265
246 356 438 711 601 1115 271 671 403 1361 403 2100 -1 718 -116 1333 -386
2053 -26 69 -124 291 -185 417 -50 102 -107 208 -224 413 -116 203 -370 554
-569 787 -102 119 -433 454 -546 551 -41 35 -83 72 -95 83 -28 26 -201 159
-304 234 -195 143 -488 328 -661 417 -52 26 -115 59 -140 72 -25 13 -109 53
-188 89 -658 299 -1338 460 -2077 494 -241 11 -295 11 -525 -1z m1169 -1944
c12 -12 12 -19 1 -47 -7 -18 -19 -40 -25 -48 -7 -8 -36 -62 -66 -120 -30 -58
-56 -107 -59 -110 -6 -6 -92 -173 -182 -355 -38 -77 -74 -149 -80 -160 -7 -11
-20 -36 -30 -55 -10 -19 -41 -75 -68 -125 -27 -49 -49 -97 -49 -106 -1 -8 -14
-33 -31 -55 -16 -21 -30 -47 -30 -57 0 -11 -14 -32 -31 -48 l-31 -29 -1185 0
-1184 0 -52 -22 c-113 -47 -159 -104 -184 -229 -15 -77 -15 -81 6 -145 12 -36
29 -80 37 -97 22 -43 90 -104 123 -110 14 -3 44 -12 66 -21 35 -13 148 -15
885 -16 465 -1 870 -6 900 -10 222 -35 500 -134 637 -227 18 -13 36 -23 39
-23 3 0 26 -16 52 -35 26 -19 65 -48 88 -64 76 -57 254 -243 254 -266 0 -5 9
-18 20 -30 29 -31 101 -165 170 -320 28 -63 61 -196 86 -350 17 -102 22 -724
28 -3217 l1 -687 -693 -3 c-677 -3 -694 -3 -708 16 -12 17 -14 299 -14 1949
l0 1929 -32 61 c-37 69 -94 115 -178 146 -53 19 -88 20 -945 24 -662 3 -900 7
-930 17 -22 6 -69 18 -105 26 -78 18 -127 34 -240 79 -301 120 -531 303 -696
552 -41 61 -74 114 -74 117 0 3 -15 38 -34 78 -48 100 -65 151 -81 233 -8 39
-22 90 -31 115 -14 38 -17 89 -18 320 -1 261 0 278 22 338 12 34 22 73 22 86
0 31 46 172 65 199 8 12 15 30 15 40 0 24 31 79 142 252 75 117 265 296 403
382 72 44 167 89 270 129 50 19 101 39 114 45 14 6 79 22 145 37 l121 27 1650
5 c908 3 1659 3 1670 1 11 -2 26 -9 34 -16z m956 -2113 c22 -7 83 -14 135 -18
52 -3 118 -12 145 -19 28 -8 73 -16 100 -20 57 -8 285 -73 299 -86 6 -5 19 -9
30 -9 12 0 44 -11 71 -25 28 -13 58 -25 67 -25 27 0 350 -163 418 -210 177
-124 312 -235 415 -341 113 -118 172 -184 192 -218 9 -14 22 -30 28 -36 19
-18 128 -188 172 -270 83 -153 120 -236 160 -355 8 -25 23 -67 33 -95 31 -86
60 -197 75 -296 9 -51 20 -121 25 -154 16 -97 12 -679 -4 -750 -8 -33 -17 -85
-21 -115 -7 -62 -44 -208 -81 -318 -13 -40 -24 -77 -24 -83 0 -7 -9 -30 -19
-53 -10 -23 -34 -76 -51 -118 -18 -43 -39 -83 -46 -89 -8 -6 -14 -19 -14 -29
0 -9 -11 -34 -25 -54 -14 -20 -25 -39 -25 -41 0 -14 -87 -148 -159 -245 -104
-140 -331 -370 -461 -466 -191 -141 -395 -254 -610 -338 -52 -21 -104 -41
-115 -46 -11 -5 -47 -16 -80 -25 -33 -9 -100 -27 -150 -41 -118 -32 -347 -74
-460 -84 -49 -4 -163 -9 -252 -9 -163 -1 -163 -1 -163 22 -7 458 -9 1297 -5
1310 6 15 24 17 154 17 135 0 177 5 379 42 28 5 52 13 52 18 0 4 16 11 35 14
33 7 149 58 212 94 92 54 236 175 293 248 27 35 50 67 50 70 0 4 7 15 15 24
24 27 114 215 124 262 6 24 17 57 26 74 8 16 15 43 15 59 0 17 7 86 17 154 15
116 15 134 -1 279 -20 186 -49 293 -117 430 -110 222 -273 388 -491 500 -177
90 -325 121 -622 128 -159 3 -192 13 -200 57 -2 12 -11 85 -20 162 -8 77 -22
160 -31 184 -8 24 -15 53 -15 64 0 17 -38 146 -60 202 -16 42 -95 210 -100
215 -3 3 -18 31 -34 63 -15 31 -33 63 -40 70 -7 7 -25 30 -39 52 -48 72 -61
90 -101 132 -45 49 -76 97 -76 118 0 8 8 19 18 25 23 14 901 7 957 -8z m-2695
-1624 c20 -21 20 -32 20 -658 0 -626 0 -637 -20 -658 l-21 -22 -1402 0 c-771
0 -1407 4 -1414 8 -25 16 -33 61 -19 100 23 61 65 164 86 210 11 23 20 49 20
56 0 7 6 19 14 25 8 7 17 28 21 47 3 20 16 57 27 83 32 71 45 103 61 146 9 22
32 78 51 125 20 46 36 88 36 94 0 5 11 32 24 60 13 28 32 71 41 96 10 25 30
75 46 111 16 36 29 71 29 79 0 7 14 37 31 66 l30 54 1159 0 1159 0 21 -22z
m-451 -3746 c33 -16 36 -20 36 -62 -1 -33 -5 -46 -17 -48 -10 -2 -18 6 -22 21
-10 42 -45 77 -76 77 -32 0 -60 -23 -60 -49 0 -25 24 -49 106 -103 39 -27 76
-59 82 -73 29 -63 2 -139 -61 -168 -45 -22 -148 -22 -210 -1 l-47 16 0 60 c0
68 14 74 42 18 27 -52 52 -72 100 -77 51 -6 71 10 66 54 -2 22 -17 37 -65 69
-84 54 -110 80 -124 122 -14 43 0 98 33 129 48 44 144 51 217 15z m333 -29
c11 -27 46 -111 78 -187 31 -77 67 -150 79 -162 13 -13 20 -28 16 -34 -3 -6
-45 -10 -96 -10 -81 0 -89 2 -89 19 0 11 5 21 12 23 9 3 8 15 -4 44 l-16 39
-66 3 -66 3 -17 -36 c-14 -29 -15 -40 -5 -55 21 -34 14 -40 -42 -40 -64 0 -78
14 -43 44 18 15 42 68 83 179 49 134 56 162 45 178 -18 30 -5 39 55 39 l55 0
21 -47z m382 38 c3 -5 -1 -14 -9 -21 -12 -10 -15 -45 -15 -186 l0 -174 48 0
c54 0 85 17 107 61 26 49 39 36 31 -32 -3 -33 -8 -65 -12 -70 -3 -5 -79 -9
-170 -9 -154 0 -164 1 -164 19 0 10 7 21 15 25 13 4 15 33 15 174 0 139 -3
172 -15 182 -8 7 -15 19 -15 26 0 11 20 14 89 14 50 0 92 -4 95 -9z m406 -108
c24 -65 46 -121 49 -125 4 -4 27 49 51 117 l45 125 62 0 c65 0 72 -6 51 -39
-9 -15 -6 -48 16 -157 34 -177 43 -202 68 -210 34 -10 52 21 114 189 48 132
56 162 46 178 -21 33 -14 39 48 39 59 0 60 0 78 -37 11 -21 41 -92 67 -158 56
-142 83 -196 103 -211 31 -23 2 -34 -88 -34 -90 0 -112 8 -85 30 19 16 19 33
0 70 -15 29 -19 30 -80 30 -61 0 -65 -1 -80 -30 -17 -33 -20 -60 -5 -60 6 0
10 -9 10 -19 0 -18 -9 -20 -155 -20 -146 -1 -155 0 -155 18 0 11 5 21 12 23 9
3 7 30 -6 109 -9 57 -20 107 -25 112 -4 4 -28 -52 -53 -125 -40 -119 -48 -133
-68 -133 -20 0 -29 16 -73 134 -57 150 -57 150 -77 3 -12 -86 -12 -94 5 -106
28 -20 9 -36 -45 -36 -55 0 -72 15 -43 39 16 13 24 45 42 156 24 158 26 212 9
217 -6 2 -8 10 -4 16 4 7 31 12 65 12 l59 0 42 -117z m1059 -4 c52 -66 101
-128 108 -137 10 -14 12 2 13 95 0 88 -3 115 -16 127 -23 23 -8 36 41 36 48 0
67 -16 42 -35 -14 -10 -17 -40 -19 -206 l-3 -194 -36 -3 c-36 -3 -38 -1 -155
148 l-119 152 -3 -127 c-2 -103 0 -129 13 -141 22 -23 9 -34 -40 -34 -50 0
-64 13 -41 39 23 25 24 342 1 365 -28 28 -17 36 53 36 l67 0 94 -121z m1585
73 c13 -82 0 -110 -24 -52 -13 31 -54 60 -85 60 -13 0 -15 -24 -15 -179 0
-109 4 -182 10 -186 27 -17 2 -25 -75 -25 -47 0 -85 3 -85 6 0 3 7 12 15 21
13 12 15 44 13 186 l-3 172 -29 -2 c-32 -2 -64 -27 -77 -60 -9 -25 -29 -31
-29 -9 0 7 3 34 6 59 l7 46 51 3 c28 2 110 2 182 0 l132 -3 6 -37z m-923 27
c95 -32 145 -110 136 -215 -6 -79 -33 -125 -95 -162 -44 -25 -56 -27 -189 -31
-92 -2 -143 0 -143 7 0 5 7 12 15 16 23 9 22 352 -2 378 -16 17 -11 18 114 18
71 0 145 -5 164 -11z m413 -62 c16 -39 39 -94 51 -122 12 -27 32 -77 45 -110
12 -33 33 -70 46 -82 13 -12 24 -25 24 -28 0 -3 -43 -5 -95 -5 -57 0 -95 4
-95 10 0 6 5 10 10 10 13 0 13 41 0 74 -9 25 -13 26 -84 26 l-75 0 -12 -40
c-9 -31 -10 -43 -1 -52 22 -22 12 -28 -43 -28 -30 0 -55 3 -55 6 0 3 9 13 19
22 11 10 35 58 54 107 88 230 90 237 78 256 -17 28 -15 29 47 29 l57 0 29 -73z
m764 1 c17 -40 54 -128 82 -195 29 -67 58 -124 66 -127 8 -3 14 -10 14 -16 0
-6 -35 -10 -90 -10 -89 0 -101 5 -78 28 9 9 8 21 -2 52 l-13 40 -77 0 -78 0
-10 -42 c-9 -35 -8 -45 5 -60 15 -17 13 -18 -40 -18 -32 0 -57 5 -57 10 0 6 4
10 10 10 14 0 34 43 90 196 52 145 58 172 38 192 -9 9 1 12 49 12 l60 0 31
-72z"/>
<path d="M5455 2367 c-8 -21 -18 -50 -21 -65 -5 -26 -4 -28 34 -27 22 0 43 3
46 6 3 3 -5 32 -18 65 l-25 59 -16 -38z"/>
<path d="M6874 2363 c-8 -21 -18 -50 -21 -64 -5 -24 -3 -25 39 -25 23 1 43 6
43 11 0 14 -36 115 -41 115 -2 0 -11 -17 -20 -37z"/>
<path d="M7937 2494 c-4 -4 -7 -83 -7 -176 l0 -168 33 0 c78 1 120 60 120 169
0 74 -22 133 -58 157 -26 17 -78 28 -88 18z"/>
<path d="M8370 2385 c-17 -43 -40 -107 -40 -112 0 -2 24 -3 53 -1 60 3 60 5
23 90 l-24 53 -12 -30z"/>
<path d="M9135 2388 c-21 -57 -35 -102 -35 -109 0 -5 23 -9 50 -9 28 0 50 4
50 10 0 5 -9 34 -21 65 -20 53 -35 68 -44 43z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
